// atoms
import MultipleSelect from "@/components/atoms/VSelect/MultipleSelect";

// Organisms
import SnackBar from "@/components/organisms/SnackBar/SnackBar";
import LoadingSpinner from "@/components/organisms/LoadingSpinner/LoadingSpinner";
import EditDialog from "@/components/organisms/VDialog/EditDialog";
// calculate
import SalesCalculaterMainTable from "@/components/organisms/FilterList/salesReleaseList.vue";
import header from "@/assets/table/sales/headersCalculateSales";
import ReleaseChangeForm from "@/components/pages/sales/calculate/ReleaseChangeForm";
import numFilterDialog from "@/components/organisms/VDialog/numFilterDialog";
import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  components: {
    LoadingSpinner,
    MultipleSelect,

    SnackBar,
    SalesCalculaterMainTable,
    EditDialog,
    ReleaseChangeForm,
    numFilterDialog,
  },
  data() {
    return {
      header: header.filter((data) => {
        return this.$store.state.auth_grade < 2
          ? data.value !== "check_status"
          : data;
      }),
      totalArea: [
        "checkbox",
        "year",
        "month",
        "kind",
        "season",
        "order",
        "buyer",
        "item",

        "placeForward",
      ],
    };
  },

  computed: {
    ...mapGetters({
      salesData: "getSalesList",
      releaseList: "getsalesCacList",
      calculateTotal: "getCalculateTotal",
    }),

    selected: {
      get() {
        return this.$store.state.selected;
      },
      set(data) {
        this.$store.state.selected = data;
      },
    },
    heightLeftDatatable() {
      return `${this.windowHeight - 200}px`;
    },

    avgUnitPrice() {
      return this.calculateTotal.salesTotalAmount &&
        this.calculateTotal.salesQuantity
        ? parseFloat(
            (
              this.calculateTotal.salesTotalAmount /
              this.calculateTotal.salesQuantity
            ).toFixed(2),
          )
        : 0;
    },
  },

  methods: {
    //매출 수량, 메모 수정 dialog
    async updataRelease() {
      if (
        this.modifyRelease.dialogKind === "eschamge" &&
        (this.modifyRelease.eschamge_sales === "" ||
          this.modifyRelease.eschamge_sales === "0")
      ) {
        this.snackbar_text = `환율을 입력해주세요`;
        this.snackbar = true;
        return;
      }
      if (
        this.modifyRelease.sales_total_quantity === "" ||
        this.modifyRelease.sales_total_quantity === "0"
      ) {
        this.$store.commit("setSnackBar", `매출 수량을 입력해주세요`);
        return;
      }

      this.$store.commit("setChangeDataDialog");
      await this.produceRelease();
      await this.putRelease(this.modifyRelease);
      this.$store.commit("setChangeData");
    },
    confirmDialogOpen(kind) {
      this.setCheckSelected();
      if (kind === "carry" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `이월 할 건을 선택해주세요.`);
        return;
      }
      if (kind === "end" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `마감 할 건을 선택해주세요.`);
        return;
      }
      if (kind === "reject" && !this.checkSelected()) {
        this.$store.commit("setSnackBar", `반려 할 건을 선택해주세요.`);
        return;
      }
      if (
        confirm(
          `${this.$store.state.selected.length} 건을 ${
            kind === "carry"
              ? "이월"
              : kind === "end"
              ? `${this.$store.state.auth_grade >= 2 ? "마감" : "마감 요청"}`
              : "반려"
          } 하시겠습니까?`,
        )
      ) {
        this.$store.dispatch("TASK_SELECT_SALESCAC_SAVE", kind);
      }
    },
    checkSelected() {
      return this.$store.state.selected.length ? true : false;
    },
    setCheckSelected() {
      const selected = [];
      this.releaseList.forEach((x) => {
        if (x.checkBox) {
          selected.push(x);
        }
      });
      this.$store.commit("setSelected", selected);
    },
  },
  created() {
    this.$store.commit("setSelected");
    const promises = [];
    this.$store.dispatch("GET_SELECT_CLIENT_LIST");
    // this.$store.dispatch("SET_ITEMLIST");
    this.$store.dispatch("SET_API_LOADING", false);
    // });
  },
  // sockets: {
  //   async sales(data) {
  //     if (this.$store.state.auth_company === data.company)
  //       await this.$store.dispatch("GET_RELEASELIST", {
  //         kind: "salescalculate",
  //       });
  //   },
  // },
};
