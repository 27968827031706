<template>
  <v-dialog v-model="dialog" max-width="500px" @keydown.esc="close">
    <v-card @keypress.enter="$emit('updataRelease')">
      <v-card-title>
        <span class="headline"
          >{{
            this.release.dialogKind === "quantity"
              ? "매출 수량 변경"
              : this.release.dialogKind === "memo"
              ? "메 모"
              : this.release.dialogKind === "eschamge"
              ? "환 율"
              : this.release.dialogKind === "price"
              ? "매출 단가 수정"
              : ""
          }}
        </span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="release.dialogKind === 'quantity'"
              cols="12"
              class="pt-0 pb-0 mt-0 mb-0"
            >
              <v-text-field
                v-model="release.sales_total_quantity"
                autofocus
                type="number"
                label="매출 수량"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="release.dialogKind === 'memo'"
              cols="12"
              class="pt-0 pb-0 mt-0 mb-0"
            >
              <v-textarea
                v-model="release.memo_sales"
                autofocus
                rows="4"
                label="메 모"
              ></v-textarea>
            </v-col>
            <v-col
              v-if="release.dialogKind === 'eschamge'"
              cols="12"
              class="pt-0 pb-0 mt-0 mb-0"
            >
              <v-text-field
                v-model="release.eschamge_sales"
                autofocus
                type="number"
                label="환율"
                suffix="₩"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="release.dialogKind === 'price'"
              cols="12"
              class="pt-0 pb-0 mt-0 mb-0"
            >
              <v-text-field
                v-model="release.unit_price_of_sales"
                autofocus
                label="단가"
                :prefix="release.currency_sign"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> 취소 </v-btn>
        <v-btn
          color="blue darken-1"
          text
          type="number"
          @click="$emit('updataRelease')"
        >
          승인
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      dialog: "getChangeDialog",
      release: "getChangeData",
    }),
  },
  methods: {
    close() {
      this.$store.commit("setChangeData");
      this.$store.commit("setChangeDataDialog");
    },
  },
};
</script>

<style></style>
